<template>
    <div>
        <div class="header-fixed">
            <!-- Search Block -->
            <div class="d-none mb-3 bg-light p-3 border-bottom mb-4">
                <div class="input-group input-group-merge m-0">
                    <input type="search" class="form-control form-control-prepended search"
                        placeholder="Cari formulir disini...">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <span class="fe fe-search"></span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Main Content -->
            <div class="container">
                <div class="section">
                    <div class="row justify-content-center">
                        <div class="col-md-10">
                            
                            <div v-if="school_info.id == ''" class="d-block">
                            <!-- #### Loader untuk school information #### -->
                                <div class="d-block bg-light rounded-lg p-md-4 p-3">
                                    <div class="row row-sm align-items-center">
                                        <div class="col-md-12 mb-5">
                                            <div class="d-flex align-items-center">
                                                <div class="skeleton-bar flex-shrink-0 mr-3">
                                                    <div class="sb-icon-circle-lg sb-darker"></div>
                                                </div>
                                                <div class="w-100">
                                                    <div class="skeleton-bar">
                                                        <div class="sb-md mb-2 sb-darker"></div>
                                                        <div class="sb-sm sb-darker"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="d-block">
                                                <div class="row row-sm">
                                                    <div class="col-md-4 mb-md-0 mb-3">
                                                        <div class="skeleton-bar">
                                                            <div class="sb-sm mb-2 sb-darker sb-thin"></div>
                                                            <div class="sb-lg sb-darker mb-2"></div>
                                                            <div class="sb-md sb-darker"></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-md-0 mb-3">
                                                        <div class="skeleton-bar">
                                                            <div class="sb-sm mb-2 sb-darker sb-thin"></div>
                                                            <div class="sb-lg sb-darker mb-2"></div>
                                                            <div class="sb-md sb-darker"></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="skeleton-bar">
                                                            <div class="sb-sm mb-2 sb-darker sb-thin"></div>
                                                            <div class="sb-md sb-darker"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- School Information -->
                            <div v-if="school_info.id">
                                <div class="d-block bg-light rounded-lg p-md-4 p-3">
                                    <div class="row row-sm align-items-center">
                                        <div class="col-md-12 mb-3">
                                            <div class="d-flex align-items-center">
                                                <img :src="school_info.logo" :style="school_info.logo_style" class="lozad">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="d-block">                                                
                                                <div class="row">
                                                    <!-- Address List -->
                                                    <div class="col-md-12">
                                                        <div class="d-block py-3 border-top">
                                                            <div class="row row-sm">
                                                                <div class="col-md-10 row">
                                                                    <div v-for="(item,index) in school_info.address " :key="index" class="col-6 d-block mb-md-0 mt-4">
                                                                        <div class="d-block small mb-1"><span class="fe fe-map-pin mr-2"></span>Alamat</div>
                                                                        <div class="d-block">{{item}}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-2 text-left">
                                                                    <div class="d-block mb-md-0 mt-4">
                                                                        <div class="d-block small mb-1"><span class="fe fe-phone mr-2"></span>Kontak</div>
                                                                        <div v-for="(item,index) in school_info.phone " :key="index" class="d-block">{{item}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- #### Empty state untuk school information #### -->
                                <!-- <div v-if="!InfoSchool.status" class="empty-content" data-empty-title="Informasi sekolah tidak tersedia">
                                    <div class="d-block bg-light rounded-lg p-md-4 p-3">
                                        <div class="row row-sm align-items-center">
                                            <div class="col-md-5">
                                                <div class="d-flex align-items-center">
                                                    <div class="skeleton-bar skeleton-empty flex-shrink-0 mr-3">
                                                        <div class="sb-icon-circle-lg sb-darker"></div>
                                                    </div>
                                                    <div class="w-100">
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-md mb-2 sb-darker"></div>
                                                            <div class="sb-sm sb-darker"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="d-block border-left pl-4">
                                                    <div class="d-block mb-3 border-bottom pb-3">
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-sm mb-2 sb-darker sb-thin"></div>
                                                            <div class="sb-md sb-darker"></div>
                                                        </div>
                                                    </div>
                                                    <div class="row row-sm">
                                                        <div class="col-md-7">
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-sm mb-2 sb-darker sb-thin"></div>
                                                                <div class="sb-lg sb-darker mb-2"></div>
                                                                <div class="sb-md sb-darker"></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-sm mb-2 sb-darker sb-thin"></div>
                                                                <div class="sb-md sb-darker"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- #### -->

                            </div>

                            <!-- Loader User -->
                            <div v-if="userdata.fetchUsers" class="d-block mt-4">
                                <div class="d-block border rounded-lg p-md-4 p-3">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="mr-3 w-100">
                                            <div class="d-flex align-items-center">
                                                <div class="skeleton-bar flex-shrink-0 mr-3">
                                                    <div class="sb-icon-circle"></div>
                                                </div>
                                                <div class="w-100">
                                                    <div class="skeleton-bar">
                                                        <div class="sb-sm mb-2"></div>
                                                        <div class="sb-xs sb-thin"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-shrink-0">
                                            <div class="skeleton-bar">
                                                <div class="sb-140"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Welcome User -->
                            <div v-if="userdata.status">
                                <div v-if="userdata.role == 'siswa'" class="d-block mt-4">
                                    <div class="d-block rounded-lg p-md-4 p-3">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="mr-3">
                                                <div class="d-flex align-items-center">
                                                    <div class="icon-circle mr-3 flex-shrink-0">
                                                        <span class="fe fe-user text-muted"></span>
                                                    </div>
                                                    <div>
                                                        <div class="h3 mb-0">Hi, {{userdata.name}}</div>
                                                        <div class="d-block text-sm">{{userdata.email}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <router-link to="calon-siswa/formulir">
                                                    <div class="d-flex align-items-center">
                                                        Lihat Formulir Saya
                                                        <span class="fe fe-arrow-right-circle ml-3 text-md"></span>
                                                    </div>                                                
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-12">

                        <div v-if="fetchBanner" class="d-block">
                            <div class="skeleton-bar">
                                <div class="sb-banner"></div>
                            </div>
                        </div>

                        <!-- PPDB Banner -->
                        <div v-if="!fetchBanner" class="d-block mb-4">
                            
                            <div v-if="!listBanner.status" class="empty-content" data-empty-title="Daftar banner tidak tersedia">
                                <div class="skeleton-bar skeleton-empty">
                                    <div class="sb-banner"></div>
                                </div>
                            </div>

                            <VueSlickCarousel v-if="listBanner.status" v-bind="settings" class="ppdb-banner banner-slider-home">
                                <div v-for="(item,index) in listBanner.data" :key="index">
                                    <a target="blank" :href="item.url" class="lozad ppdb-banner--item"
                                    v-bind:style="{ 'background-image': 'url(' + item.cover + ')' }"></a>
                                </div>
                            </VueSlickCarousel>
                        </div>
                        <!-- -->
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <!-- Announcement List -->                        
                        <div class="section pb-3">
                            <div class="d-flex align-items-center justify-content-between mb-3 pb-3 border-bottom">
                                <h2 class="m-0">Pengumuman</h2>
                                <router-link to="/pengumuman">Lihat Semua</router-link>
                            </div>

                            <div v-if="fetchingAnnouncement" class="row">
                                <div class="col-md-4 col-6 mb-3 col-load">
                                    <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                        <div class="d-flex flex-column justify-content-between h-100">
                                            <div class="flex-grow-1 mb-4">
                                                <div class="skeleton-bar">
                                                    <div class="sb-lg mb-2"></div>
                                                    <div class="sb-md mb-2"></div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <div class="skeleton-bar">
                                                        <div class="sb-100 sb-thin"></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="skeleton-bar">
                                                        <div class="sb-60 sb-thin"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-6 mb-3 col-load">
                                    <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                        <div class="d-flex flex-column justify-content-between h-100">
                                            <div class="flex-grow-1 mb-4">
                                                <div class="skeleton-bar">
                                                    <div class="sb-lg mb-2"></div>
                                                    <div class="sb-md mb-2"></div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <div class="skeleton-bar">
                                                        <div class="sb-100 sb-thin"></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="skeleton-bar">
                                                        <div class="sb-60 sb-thin"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 hide-mobile mb-3 col-load">
                                    <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                        <div class="d-flex flex-column justify-content-between h-100">
                                            <div class="flex-grow-1 mb-4">
                                                <div class="skeleton-bar">
                                                    <div class="sb-lg mb-2"></div>
                                                    <div class="sb-md mb-2"></div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <div class="skeleton-bar">
                                                        <div class="sb-100 sb-thin"></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="skeleton-bar">
                                                        <div class="sb-60 sb-thin"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="!fetchingAnnouncement">
                                <div v-if="listAnnouncement.length == 0" class="empty-content" data-empty-title="Daftar pengumuman tidak tersedia">
                                    <div class="row">
                                        <div class="col-md-4 col-6 mb-3 col-load">
                                            <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1 mb-4">
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-md mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <div>
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-100 sb-thin"></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-60 sb-thin"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-6 mb-3 col-load">
                                            <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1 mb-4">
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-md mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <div>
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-100 sb-thin"></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-60 sb-thin"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-3 col-load hide-mobile">
                                            <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1 mb-4">
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-md mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <div>
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-100 sb-thin"></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-60 sb-thin"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="listAnnouncement.length > 0" class="row">
                                    <div v-for="(item, index) in listAnnouncement" :key="index" class="col-md-4 col-6 mb-3">
                                        <router-link v-bind:to="'/pengumuman/detail/' + item.id"
                                            class="d-block pb-3 border-bottom h-100 link-w-icon-circle">
                                            <div class="d-flex flex-column justify-content-between h-100">
                                                <div class="flex-grow-1">
                                                    <div class="d-flex align-items-start mb-4">
                                                        <div
                                                            class="icon-circle icon-circle-primary-light mr-3 flex-shrink-0">
                                                            <span class="fe fe-bell"></span>
                                                        </div>
                                                        <h3 class="m-0 text-dark">{{item.title}}</h3>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="text-dark small"><span
                                                            class="fe fe-calendar mr-2"></span>{{item.date}}</div>
                                                    <div class="text-dark small"><span class="fe fe-user mr-2"></span>{{item.author}}
                                                    </div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- Program List -->
                        <div class="section">
                            <div class="d-flex align-items-center justify-content-between mb-3 pb-3 border-bottom">
                                <h2 class="m-0">Program</h2>
                                <router-link to="/program">Lihat Semua</router-link>
                            </div>
                            
                            <div v-if="fetchingProgram" class="row">
                                <div v-for="(item,index) in [1,2,3,4]" :key="index" class="col-md-3 col-6 mb-3 col-load">
                                    <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                        <div class="d-flex flex-column justify-content-between h-100">
                                            <div class="flex-grow-1">
                                                <div class="card-img-news mb-3 add-skeleton-load delay-1"></div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-lg mb-2"></div>
                                                    <div class="sb-md mb-2"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-sm sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-bold"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="!fetchingProgram">
                                <div v-if="listProgram.length > 0" class="row">
                                    <div v-for="(item, index) in listProgram" :key="index" class="col-md-3 col-6 mb-3">
                                        <router-link v-bind:to="'/program/detail/' + item.id"
                                            class="d-block pb-3 border-bottom h-100 link-btn-white">
                                            <div class="d-flex flex-column justify-content-between h-100">
                                                <div class="flex-grow-1">
                                                    <div class="card-img-news mb-3 lozad"
                                                        v-bind:style="{ 'background-image': 'url(' + item.cover + ')' }">
                                                    </div>
                                                    <h4 class="mb-4 text-dark">{{item.title}}</h4>
                                                </div>
                                                <div>
                                                    <div class="d-block text-dark small mb-2">{{item.date}} - {{item.author}}</div>
                                                    <router-link v-bind:to="'/program/detail/' + item.id" ><div class="btn btn-block btn-sm btn-white">Lihat Program</div></router-link>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>

                                <div v-if="listProgram.length == 0" class="empty-content" data-empty-title="Daftar program tidak tersedia">
                                    <div class="row">
                                        <div v-for="(item,index) in [1,2,3,4]" :key="index" class="col-md-3 mb-3 col-6 col-load">
                                            <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1">
                                                        <div class="card-img-news mb-3 add-skeleton-load skeleton-empty delay-1"></div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-md mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-sm sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-bold"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- News List -->
                        <div class="section">
                            <div class="d-flex align-items-center justify-content-between mb-3 pb-3 border-bottom">
                                <h2 class="m-0">Berita</h2>
                                <router-link to="/berita">Lihat Semua</router-link>
                            </div>

                            <div v-if="fetchingNews" class="row">
                                <div v-for="(item,index) in [1,2,3,4]" :key="index" class="col-md-3 col-6 mb-3 col-load">
                                    <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                        <div class="d-flex flex-column justify-content-between h-100">
                                            <div class="flex-grow-1">
                                                <div class="card-img-news mb-3 add-skeleton-load delay-1"></div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-lg mb-2"></div>
                                                    <div class="sb-md mb-2"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-sm sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-bold"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="!fetchingNews">
                                <div v-if="listNews.length > 0" class="row">
                                    <div v-for="(item, index) in listNews" :key="index" class="col-md-3 col-6 mb-3">
                                        <router-link v-bind:to="'/berita/detail/' + item.id"
                                            class="d-block pb-3 border-bottom h-100 link-btn-white">
                                            <div class="d-flex flex-column justify-content-between h-100">
                                                <div class="flex-grow-1">
                                                    <div class="card-img-news mb-3 lozad"
                                                        v-bind:style="{ 'background-image': 'url(' + item.cover + ')' }">
                                                    </div>
                                                    <h4 class="mb-4 text-dark">{{item.title}}</h4>
                                                </div>
                                                <div>
                                                    <div class="d-block text-dark small mb-2">{{item.date}} - {{item.author}}</div>
                                                    <router-link v-bind:to="'/berita/detail/' + item.id" ><div class="btn btn-block btn-sm btn-white">Lihat Berita</div></router-link>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>

                                <div v-if="listNews.length == 0" class="empty-content" data-empty-title="Daftar berita tidak tersedia">
                                    <div class="row">
                                        <div v-for="(item,index) in [1,2,3,4]" :key="index" class="col-md-3 mb-3 col-6 col-load">
                                            <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1">
                                                        <div class="card-img-news mb-3 add-skeleton-load skeleton-empty delay-1"></div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-md mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-sm sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-bold"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import {
        mapState
    } from 'vuex'
    import VueSlickCarousel from 'vue-slick-carousel'
    import lozad from 'lozad';

    export default {
        name: 'Beranda',
        components: {
            VueSlickCarousel
        },
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Beranda'
        },
        data() {
            return {
                fetchingUsers: true,
                search: "",
                pageOfItems: [],
                listNews: [],
                fetchingNews: true,
                listAnnouncement: [],
                fetchingAnnouncement: true,
                listProgram: [],
                fetchingProgram: true,
                InfoSchool: [],
                fetchingInfoSchool: true,
                // VueSlick Settngs
                settings: {
                    arrows : true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    dots : false
                },
                listBanner: [],
                fetchBanner: true,
            }
        },
        mounted() {
            this.fetchingUsers = false;
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);        
        },
        beforeMount() {
            this.$store.dispatch('loadUserData');
        },
        computed: mapState(['userdata','school_info']),
        created() {
            this.getNews();
            this.getAnnouncement();
            this.getProgram();
            this.getBanner();
        },
        methods: {
            getNews: function () {
                this.fetchingNews = true;
                let uri = this.$apiconfig + 'website/news/list';
                this.$http.get(uri, {
                    params: {
                        limit: 4
                    }
                }
                ).then(res => {
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.listNews = getResponse.data;
                    } else {
                        this.listNews = [];
                    }
                    this.fetchingNews = false;
                });
            },
            getAnnouncement: function () {
                this.fetchingAnnouncement = true;
                let uri = this.$apiconfig + 'website/announcement/list';

                this.$http.get(uri, {
                    params: {
                        limit: 3
                    }
                }).then(res => {
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.listAnnouncement = getResponse.data;
                    } else {
                        this.listAnnouncement = [];
                    }
                    this.fetchingAnnouncement = false;
                });
            },
            getProgram: function () {
                this.fetchingProgram = true;
                let uri = this.$apiconfig + 'website/program/list';

                this.$http.get(uri, {
                    params: {
                        limit: 4
                    }
                }).then(res => {
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.listProgram = getResponse.data;
                    } else {
                        this.listProgram = [];
                    }
                    this.fetchingProgram = false;
                });
            },
            getBanner: function () {
                this.fetchBanner = true;
                let uri = this.$apiconfig + 'website/banner/list';

                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listBanner = getResponse;
                    this.fetchBanner = false;
                });
            },
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>