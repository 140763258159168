import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/admin/authentication/Signin.vue';
import Beranda from '@/views/siswa/Beranda.vue';
import VueMeta from 'vue-meta';
import Axios from 'axios';
import configuration from './configuration';
import VueBodyClass from 'vue-body-class';

import Home from '@/views/admin/dashboard/Dashboard.vue';
import SideBar from '@/layouts/SideBar.vue';
import MainWebsite from '@/layouts/MainWebsite.vue';
import PageNotFound from '@/layouts/PageNotFound.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [{
        path: '/sign-admin',
        name: 'login',
        component: Login
    },
    {
        path: '/admin/dashboard',
        name: 'admin.dashboard',
        component: Home,
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/periode',
        name: 'master.periode',
        component: () => import('@/views/admin/master/MasterPeriode.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/unit',
        name: 'master.unit',
        component: () => import('@/views/admin/master/MasterUnit.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/subunit',
        name: 'master.subunit',
        component: () => import('@/views/admin/master/MasterSubUnit.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/formulir',
        name: 'master.formulir',
        component: () => import('@/views/admin/master/MasterFormulir.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/gelombang',
        name: 'master.gelombang',
        component: () => import('@/views/admin/master/MasterGelombang.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/formulir/setup/:id',
        name: 'master.formulir.setup',
        component: () => import('@/views/admin/gelombang/FormulirSetup.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/gelombang/detail/:id',
        name: 'master.gelombang.detail',
        component: () => import('@/views/admin/gelombang/GelombangDetail.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/registrasi/all',
        name: 'registrasi.all',
        component: () => import('@/views/admin/registrasi/Registrasi_per_formulir.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/registrasi/all/:id',
        name: 'registrasi.persetupformulir',
        component: () => import('@/views/admin/registrasi/Registrasi.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/registrasi/detail/:id',
        name: 'registrasi.detail',
        component: () => import('@/views/admin/registrasi/Registrasi_detail.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/registrasi-ulang',
        name: 'registrasi.ulang',
        component: () => import('@/views/admin/registrasi_ulang/Registrasi_ulang.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/registrasi-ulang/detail/:id',
        name: 'registrasi.ulang.performulir',
        component: () => import('@/views/admin/registrasi_ulang/Registrasi_ulang_per_formulir.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/siswa-baru',
        name: 'siswa_baru.index',
        component: () => import('@/views/admin/registrasi_ulang/Siswa_baru.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/siswa-baru/detail/:id',
        name: 'siswa_baru.performulir',
        component: () => import('@/views/admin/registrasi_ulang/Siswa_baru_per_formulir.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/berita',
        name: 'berita.index',
        component: () => import('@/views/admin/blog/berita/Berita.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/berita/tambah',
        name: 'berita.tambah',
        component: () => import('@/views/admin/blog/berita/BeritaTambah.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/berita/edit/:id',
        name: 'berita.edit',
        component: () => import('@/views/admin/blog/berita/BeritaEdit.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/banner',
        name: 'banner.index',
        component: () => import('@/views/admin/banner/Banner.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/banner/tambah',
        name: 'banner.tambah',
        component: () => import('@/views/admin/banner/BannerTambah.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/banner/edit/:id',
        name: 'banner.edit',
        component: () => import('@/views/admin/banner/BannerEdit.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/pengumuman',
        name: 'pengumuman.index',
        component: () => import('@/views/admin/blog/pengumuman/Pengumuman.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/pengumuman/tambah',
        name: 'pengumuman.tambah',
        component: () => import('@/views/admin/blog/pengumuman/PengumumanTambah.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/pengumuman/edit/:id',
        name: 'pengumuman.edit',
        component: () => import('@/views/admin/blog/pengumuman/PengumumanEdit.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/program',
        name: 'program.index',
        component: () => import('@/views/admin/blog/program/Program.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/program/tambah',
        name: 'program.tambah',
        component: () => import('@/views/admin/blog/program/ProgramTambah.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/program/edit/:id',
        name: 'program.edit',
        component: () => import('@/views/admin/blog/program/ProgramEdit.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/log_activities',
        name: 'LogActivities',
        component: () => import('@/views/admin/log/LogActivities.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/users',
        name: 'Users',
        component: () => import('@/views/admin/users/Users.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/profile',
        name: 'profile.index',
        component: () => import('@/views/admin/profile/Profile.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/twofactor',
        name: 'twofactor',
        component: () => import('@/views/admin/authentication/Twofactor.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },
    {
        path: '/admin/formulir/setup/component/:id',
        name: 'custom_formulir',
        component: () => import('@/views/admin/gelombang/FormulirSetupComponent.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator']
        }
    },

    /* -------------------------------------------------------------- */
    /* Router Siswa */
    {
        path: '/',
        name: 'home',
        component: Beranda,
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Beranda,
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/formulir/unit',
        name: 'unit_formulir',
        component: () => import('@/views/siswa/FormulirUnit.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/formulir/list/:id',
        name: 'list_formulir',
        component: () => import('@/views/siswa/FormulirList.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/berita',
        name: 'berita',
        component: () => import('@/views/siswa/Berita.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/berita/detail/:id',
        name: 'berita_detail',
        component: () => import('@/views/siswa/BeritaDetail.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/program',
        name: 'program',
        component: () => import('@/views/siswa/Program.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/program/detail/:id',
        name: 'program_detail',
        component: () => import('@/views/siswa/ProgramDetail.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/pengumuman',
        name: 'pengumuman',
        component: () => import('@/views/siswa/Pengumuman.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/pengumuman/detail/:id',
        name: 'pengumuman_detail',
        component: () => import('@/views/siswa/PengumumanDetail.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/sign-in',
        name: 'login',
        component: () => import('@/views/siswa/SignIn.vue')
    },
    {
        path: '/sign-up',
        name: 'signup',
        component: () => import('@/views/siswa/SignUp.vue'),
    },
    {
        path: '/formulir/informasi',
        name: 'formulir_informasi',
        component: () => import('@/views/siswa/FormulirInformasi.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/calon-siswa/formulir',
        name: 'calon_siswa_formulir',
        component: () => import('@/views/siswa/CalonSiswaFormulir.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite,
            roles : ['siswa']
        }
    },
    {
        path: '/calon-siswa/pengaturan-akun',
        name: 'calon_siswa_pengaturan_akun',
        component: () => import('@/views/siswa/CalonSiswaPengaturanAkun.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite,
            roles : ['siswa']
        }
    },
    {
        path: '/calon-siswa/formulir/detail/:id',
        name: 'calon_siswa_formulir_detail',
        component: () => import('@/views/siswa/DetailFormulir.vue'),
        meta: {
            bodyClass: 'bg-light',
            roles : ['siswa']
        }
    },
    {
        path: '/calon-siswa/formulir/registrasi/:id',
        name: 'calon_siswa_formulir_registrasi',
        component: () => import('@/components/siswa/Registrasi.vue'),
        meta: {
            bodyClass: 'bg-light',
            roles : ['siswa']
        }
    },
    {
        path: '/signout',
        name: 'signout',
        component: () => import('./views/admin/authentication/Signout.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('./views/admin/settings/Profile.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: PageNotFound,
    },      
];

const vueBodyClass = new VueBodyClass(routes);

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["login", "signup", "home", "list_formulir", "berita", "berita_detail", "program", "program_detail", "pengumuman", "pengumuman_detail", "unit_formulir"];
    const authRequired = !publicPages.includes(to.name);
    const email = localStorage.getItem('email');

    // if user login without twofactor authentication
    if (!email) {
        const token = localStorage.getItem('auth_token');
        if (authRequired && token) {
            Axios.get(configuration.host + 'auth/validate_user', {
                headers: { 'Authorization': token }
            }).then(response => {
                if (!response.data.status) {
                    localStorage.removeItem('id');
                    localStorage.removeItem('auth_token');
                    next('/');
                } else {
                    let roles = response.data.role;
                    
                    if(to.meta.roles.includes(roles)) {
                        next();
                    } else {
                        alert("Akses tidak diperbolehkan");
                        next({ name: 'home' });
                    }
                }
            }).catch(function(error) {
                console.debug(error);
            });
        } else if (authRequired && !token) {
            next({ name: 'home' });
        } else {
            next();
        }
    } else {
        if (localStorage.getItem("auth_token") === null && to.fullPath == '/') {
            return next('/admin/twofactor')
        }
    }

    vueBodyClass.guard(to, next);
});

export default router;