<template>
    <div>
        <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light navbar-clean" id="sidebar">
            <div class="container-fluid">
                <!-- Toggler -->
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- Brand -->
                <a class="navbar-brand" href=".#">
                    <img :src="school_info.logo" alt="Logo PPDB" style="width:70%" class="navbar-brand-img">
                </a>
                <!-- User (xs) -->
                <div class="navbar-user d-md-none">
                    <!-- Dropdown -->
                    <div class="dropdown">
                        <!-- Toggle -->
                        <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="avatar avatar-sm avatar-online">
                                <img src="https://scolacdn.com/frontend/argonne-img/avatars/profiles/avatar-1.jpg" class="avatar-img rounded-circle" alt="...">
                            </div>
                        </a>
                        <!-- Menu -->
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sidebarIcon">
                            <router-link to="/signout" class="dropdown-item">Keluar</router-link>
                        </div>
                    </div>
                </div>
                <!-- Collapse -->
                <div class="collapse navbar-collapse" id="sidebarCollapse">
                    <div class="d-block mb-4">
                        <div class="dropdown">
                            <button class="btn btn-profile btn-block text-left" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="d-flex align-items-center h-100 w-100">
                                    <div class="flex-shrink-0 mr-3">
                                        <div class="avatar avatar-sm avatar-online">
                                            <img src="https://scolacdn.com/frontend/argonne-img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img rounded-circle">
                                        </div>
                                    </div>
                                    <div class="w-100 mt-2">
                                        <h4 class="mb-0">admin</h4><small>Administrator PPDB</small>
                                    </div>
                                </div>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-full" aria-labelledby="dropdownMenuButtonAlias">
                                <li>
                                    <router-link to="/settings" class="dropdown-item">Pengaturan</router-link>
                                    <router-link to="/signout" class="dropdown-item">Keluar</router-link>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <form class="mt-4 mb-3 d-md-none">
                        <div class="input-group input-group-rounded input-group-merge">
                            <input type="search" class="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!-- Navigation -->

                    <ul class="navbar-nav">
                        <li class="nav-item" v-for="(item,index) in dataMenu" :key="index">
                            <!-- If not parent menu -->
                            <div v-if="!item.parent">
                                <router-link :to="{ name: item.url }" class="nav-link">
                                    <img :src="'https://scolacdn.com/frontend/argonne-img/'+item.icon" class="sidebar-img-icon">
                                    {{item.title}}
                                </router-link>
                            </div>
                            <!-- If parent menu -->
                            <div v-if="item.parent">
                                <a class="nav-link" :href="'#sidebarDashboards'+item.id" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards">
                                    <img :src="'https://scolacdn.com/frontend/argonne-img/'+item.icon" class="sidebar-img-icon">
                                    {{item.title}}
                                </a>
                                <div class="collapse" :id="'sidebarDashboards'+item.id">
                                    <ul class="nav nav-sm flex-column">
                                        <li class="nav-item" v-for="(itemDetail,indexDetail) in item.sub_menu" :key="indexDetail">
                                            <router-link :to="{ name: itemDetail.url }" class="nav-link">
                                                {{itemDetail.title}}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="mt-auto"></div>
                    <div class="text-muted"><small>2020 PPDB ONLINE. All Right Reserved.</small></div>
                </div>

            </div>
        </nav>
        <div class="main-content main-content-full">
            <div class="container-fluid">
                <div class="spacer-top"></div>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: "SideBar",
        data() {
            return {
                dataMenu: []
            }
        },
        mounted() {
            this.$store.dispatch('loadSchoolInfo');
            this.getData();
        },
        computed: mapState(['school_info']),
        methods: {
            getData() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + "admin/menu/list_menu")
                    .then((response) => {
                        this.dataMenu = response.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
        }
    }
</script>