<template>
    <div>
        <div class="header-horizontal mb-4 border-bottom">
            <div class="d-flex align-items-center justify-content-between h-100">
                <div>
                    <div v-if="school_info.header_logo == ''" class="skeleton-bar">
                        <div class="sb-sm"></div>
                        <div class="sb-xs"></div>
                    </div>
                    <router-link v-if="school_info.header_logo" to="/"><img :src="school_info.header_logo" alt="Logo PPDB" :style="school_info.header_logo_style">
                    </router-link>
                </div>
                <div class="show-mobile">
                    <div class="d-flex align-items-center">
                        <div class="dropdown">
                            <button class="btn btn-white mr-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Menu</button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <!--
                                <router-link class="dropdown-item" to="/formulir/unit">PPDB</router-link>
                                <router-link class="dropdown-item" to="/pengumuman">Pengumuman</router-link>
                                <router-link class="dropdown-item" to="/program">Program</router-link>
                                <router-link class="dropdown-item" to="/berita">Berita</router-link>
                                -->
                                <router-link v-for="(item, index) in general_menu" :key="index" :to="{ name: item.url }" class="dropdown-item">{{ item.title }}</router-link>
                            </div>
                        </div>
                        <!-- Bagian ini muncul ketika belum login -->
                        <div v-if="userdata.role != 'siswa'">
                            <router-link to="/sign-in" class="btn btn-primary">Login</router-link>
                        </div>
                        <!-- Bagian ini muncul ketika sudah login -->
                        <div v-if="userdata.logged_in && userdata.role == 'siswa'" class="dropdown">
                            <button class="btn btn-white dropdown-toggle btn-if-icon" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fe fe-user"></span></button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sidebarIcon">
                                <div class="dropdown-item">
                                    {{userdata.name}}
                                    <div class="d-block text-sm">
                                        {{userdata.email}}
                                    </div>
                                </div>
                                <hr class="dropdown-divider">
                                <router-link to="/calon-siswa/formulir" class="dropdown-item">Formulir Saya</router-link>
                                <router-link to="/calon-siswa/pengaturan-akun" class="dropdown-item">Pengaturan Akun</router-link>
                                <hr class="dropdown-divider">
                                <router-link to="/signout" class="dropdown-item">Keluar</router-link>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="hide-mobile">
                    <div class="hh-nav-list d-flex align-items-center text-right">
                        <!--
                        <router-link to="/formulir/unit" class="hh-nav-item mr-1">PPDB</router-link>
                        <router-link to="/pengumuman" class="hh-nav-item mr-1">Pengumuman</router-link>
                        <router-link to="/program" class="hh-nav-item mr-1">Program</router-link>
                        <router-link to="/berita" class="hh-nav-item mr-1">Berita</router-link>
                        -->
                        <router-link v-for="(item, index) in general_menu" :key="index" :to="{ name: item.url }" class="hh-nav-item mr-1">{{ item.title }}</router-link>

                        <!-- Bagian ini muncul ketika belum login -->
                        <div v-if="userdata.role != 'siswa'">
                            <router-link to="/sign-in" class="btn btn-primary">
                                <div class="d-flex align-items-center">
                                    <div><span class="fe fe-user mr-2"></span></div>
                                    <div>Login</div>
                                </div>
                            </router-link>
                        </div>

                        <!-- Bagian ini muncul ketika sudah login -->
                        <div v-if="userdata.logged_in && userdata.role == 'siswa'" class="dropdown">
                            <button class="btn btn-white dropdown-toggle btn-if-icon" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Akun Saya</button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sidebarIcon">
                                <div class="dropdown-item">
                                    {{userdata.name}}
                                    <div class="d-block text-sm">
                                        {{userdata.email}}
                                    </div>
                                </div>
                                <hr class="dropdown-divider">
                                <router-link to="/calon-siswa/formulir" class="dropdown-item">Formulir Saya</router-link>
                                <router-link to="/calon-siswa/pengaturan-akun" class="dropdown-item">Pengaturan Akun</router-link>
                                <hr class="dropdown-divider">
                                <router-link to="/signout" class="dropdown-item">Keluar</router-link>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <slot />
        <div id="contact" class="d-block bg-light">
            <div class="section">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-10" v-if="school_info.id">
                            <div class="row">
                                <div class="col-md-4 mb-md-0 mb-4">
                                    <router-link to="/"><img :src="school_info.header_logo" :style="school_info.header_logo_style" alt="Logo PPDB"></router-link>
                                </div>
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-8 mb-md-0 mb-4">
                                            <div class="d-block mb-3">Kontak</div>
                                            <div class="row">
                                                <div class="col-md-6 mb-md-0 mb-3 col-6">

                                                    <div class="d-flex align-items-center">
                                                        <div class="mr-2">
                                                            <div class="icon-circle icon-circle-primary-light border border-primary">
                                                                <span class="fe fe-phone"></span></div>
                                                        </div>
                                                        <div class="font-weight-bold">
                                                            <div class="d-block">{{school_info.phone[0]}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-md-0 mb-3 col-6">
                                                    <div class="d-flex align-items-center">
                                                        <div class="mr-2">
                                                            <div class="icon-circle icon-circle-primary-light border border-primary">
                                                                <span class="fe fe-phone"></span></div>
                                                        </div>
                                                        <div class="font-weight-bold">
                                                            <div class="d-block">{{school_info.phone[1]}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="d-block mb-3">Alamat Email</div>
                                            <div class="d-flex align-items-center">
                                                <div class="mr-2">
                                                    <div class="icon-circle icon-circle-primary-light border border-primary">
                                                        <span class="fe fe-mail"></span></div>
                                                </div>
                                                <div class="font-weight-bold">
                                                    <div class="d-block">{{school_info.email}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-block bg-dark text-white text-center text-sm">
            <div class="d-block p-3">
                Scola SchoolPro is a brand new product from <a href="https://scola.id" target="_blank"><img src="https://scolacdn.com/frontend/argonne-img/scola-logo-white.svg" class="mb-2 ml-1" alt="" height="18"></a>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    export default {
        mounted() {
            this.$store.dispatch('loadUserData');
            this.$store.dispatch('loadSchoolInfo');
            this.$store.dispatch('loadGeneralMenu');
        },
        computed: mapState(['userdata', 'school_info', 'general_menu']),
    }
</script>