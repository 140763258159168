import Vue  from 'vue';
import App  from './App.vue';
import router from './router';
import store from './store';
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';
// import VueAxios from 'vue-axios';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import VModal from 'vue-js-modal/dist/index.nocss';
import configuration from './configuration';
import 'vue-js-modal/dist/styles.css';
import CKEditor from 'ckeditor4-vue';
// MARKDOWN COMPONENT
import VueSimplemde from 'vue-simplemde';
import 'simplemde/dist/simplemde.min.css';

// EXCEL
import JsonExcel from "vue-json-excel";

// Markdown View
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'

import VuePageTransition from 'vue-page-transition';

// Vue Global Variabel
Vue.config.productionTip          = false;
Vue.prototype.$http               = axios;
Vue.prototype.$apiconfig          = configuration.host;
Vue.prototype.$api_upload_engine  = 'https://uploadengine.edmission.id/upload';
Vue.prototype.$beta_custom_form   = false;
Vue.prototype.$beta_cicilan       = false;
Vue.prototype.$beta_wali          = false;
Vue.prototype.$beta_cetak         = false;

// Vue Use
Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VuePageTransition);

// Vue.use(VueAxios, axios);
Vue.use(VModal)
Vue.use(CKEditor);
Vue.component('vue-simplemde', VueSimplemde);
Vue.component('markdown-it-vue', MarkdownItVue);
// Vue.component('vue-markdown', VueMarkdown);

// EXCEL
Vue.component("downloadExcel", JsonExcel);

// Add Global Auth Token
const token = localStorage.getItem('auth_token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token;
}

axios.get(configuration.host + 'setting/beta', {
  params : {
    version : 'beta_custom_form'
  }
}).then(response => {
  Vue.prototype.$beta_custom_form = response.data;
  localStorage.setItem("beta_custom_form",response.data);
});

let beta_custom_form = localStorage.getItem("beta_custom_form");
if(beta_custom_form == null) {
  beta_custom_form = false;
}
Vue.prototype.$beta_custom_form = beta_custom_form;

axios.get(configuration.host + 'setting/beta', {
  params : {
    version : 'beta_cicilan'
  }
}).then(response => {
  Vue.prototype.$beta_cicilan = response.data;
  localStorage.setItem("beta_cicilan",response.data);
});

let beta_cicilan = localStorage.getItem("beta_cicilan");
if(beta_cicilan == null) {
  beta_cicilan = false;
}
Vue.prototype.$beta_cicilan = beta_cicilan;

axios.get(configuration.host + 'setting/beta', {
  params : {
    version : 'beta_wali'
  }
}).then(response => {
  Vue.prototype.$beta_wali = response.data;
  localStorage.setItem("beta_wali",response.data);
});

let beta_wali = localStorage.getItem("beta_wali");
if(beta_wali == null) {
  beta_wali = false;
}
Vue.prototype.$beta_wali = beta_wali;

axios.get(configuration.host + 'setting/beta', {
  params : {
    version : 'beta_cetak'
  }
}).then(response => {
  Vue.prototype.$beta_cetak = response.data;
  localStorage.setItem("beta_cetak",response.data);
});

let beta_cetak = localStorage.getItem("beta_cetak");
if(beta_cetak == null) {
  beta_cetak = false;
}
Vue.prototype.$beta_cetak = beta_cetak;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

console.log(process.env.VUE_APP_ENVIRONMENT);
console.log("hello world")