<template>
    <!-- CONTENT ================================================== -->
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-xl-8 my-5">
                <div class="card shadow-sm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card-col-img" v-bind:style="{ 'background-image': 'url(' + school_info.banner_signin + ')' }"></div>
                        </div>
                        <div class="col-md-6">
                            <div class="card-body py-5">
                                <!-- Heading -->
                                <h1 class="display-4 text-center mb-3">
                                    Masuk
                                </h1>
                                <!-- Subheading -->
                                <p class="text-muted text-center mb-5">
                                    PPDB ONLINE
                                </p>
                                <!-- Form -->
                                <form v-on:submit.prevent="handleSubmit">
                                    <!-- Email address -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label>Nama Pengguna</label>
                                        <!-- Input -->
                                        <input type="username" class="form-control"
                                            :class="{ 'is-invalid': submitted && $v.username.$error }"
                                            v-model="username" placeholder="Masukan username">
                                        <div v-if="submitted && !$v.username.required" class="invalid-feedback">Username
                                            is required</div>
                                    </div>
                                    <!-- Password -->
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col">

                                                <!-- Label -->
                                                <label>Kata Sandi</label>
                                            </div>
                                            <div class="col-auto">
                                                <!-- Help text -->
                                                <!-- <a href="#" class="form-text small text-muted">
													Lupa kata sandi?
												</a> -->
                                            </div>
                                        </div> <!-- / .row -->
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <!-- input-group-merge -->
                                            <!-- Input -->
                                            <!-- Input -->
                                            <input type="password" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.password.$error }"
                                                v-model="password" placeholder="Masukan password">
                                            <div v-if="submitted && !$v.password.required" class="invalid-feedback">
                                                Password is required</div>
                                            <!-- Icon -->
                                            <!-- <div class="input-group-append">
												<span class="input-group-text">
													<i class="fe fe-eye"></i>
												</span>
											</div> -->
                                        </div>
                                    </div>
                                    <!-- Submit -->
                                    <!-- Submit -->
                                    <button type="submit" class="btn btn-lg btn-block btn-primary mb-3">
                                        Masuk Sekarang
                                    </button>

                                    <!-- Link -->
                                    <!-- <div class="text-center">
									<small class="text-muted text-center">
										Don't have an account yet? <a href="sign-up.html">Sign up</a>.
									</small>
									</div> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</template>

<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";
    import { mapState } from 'vuex'

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Admin Signin'
        },
        mounted() {
            this.$store.dispatch('loadSchoolInfo');
        },
        computed: mapState(['school_info']),
        data() {
            return {
                username: "",
                password: "",
                submitted: false
            }
        },
        validations: {
            username: {
                required
            },
            password: {
                required
            }
        },
        methods: {
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form login',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'admin/auth/signin', {
                            username: this.username,
                            password: this.password
                        })
                        .then(response => {
                            if (response.data.status === "success") {
                                const getUser = response.data.user;
                                // if users enable twofactor
                                if (getUser.require_twofactor == '1') {
                                    localStorage.setItem('name', getUser.fullname);
                                    localStorage.setItem('email', getUser.email);
                                    this.$router.push('/admin/twofactor');
                                } else {
                                    const token = getUser.key;
                                    localStorage.setItem('auth_token', token);
                                    axios.defaults.headers.common['Authorization'] = token;
                                    this.$router.push('/admin/dashboard');
                                }
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                        .catch(function (error) {
                            // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                            console.debug("Login issue: " + error)
                        });
                }
            },
        }
    }
</script>