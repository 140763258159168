import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import configuration from '../configuration';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userdata: {
            id          : '',
            username    : '',
            name        : '',
            email       : '',
            phone       : '',
            status      : false,
            logged_in   : false,
            fetchUsers  : true,
            domain      : '',
        },
        school_info: {
            id                  : '' ,
            school_title        : '' ,
            school_sub_title    : '' ,
            address             : '' ,
            logo                : '' ,
            logo_style          : '' , 
            header_logo          : '' ,
            header_logo_style   : '' ,
            phone               : '' ,
            email               : '' ,
            website             : '',
            banner_signin       : '',
            banner_signup       : '',
        },
        general_menu: [1,2,3]
    },
    mutations: {
        set_userdata(state, userdata) {
            state.userdata = userdata;
        },
        set_school_info(state, school_info) {
            state.school_info = school_info;
        },
        set_general_menu(state, general_menu) {
            state.general_menu = general_menu;
        },
    },
    actions: {
        loadUserData({
            commit
        }) {
            axios.get(configuration.host + 'auth/validate_user')
                .then(response => {
                    commit('set_userdata', response.data);
                });
        },
        loadSchoolInfo({
            commit
        }) {
            axios.get(configuration.host + 'website/school/info')
                .then(response => {
                    commit('set_school_info', response.data.data);
                });
        },
        loadGeneralMenu({
            commit
        }) {
            axios.get(configuration.host + 'website/school/menu')
                .then(response => {
                    commit('set_general_menu', response.data.data);
                });
        },
    }
});